<template>
  <div v-if="langLoaded" class="pa-5">
    <v-overlay v-if="uploading">
      <v-progress-circular indeterminate></v-progress-circular>
    </v-overlay>
    <v-card
        class="mx-auto"
    >
      <v-card-title>
        <h2 v-t="'Measures.MeasureEditing'"></h2>
        <v-spacer></v-spacer>
        <v-btn color='secondary' @click="$router.push({ name: 'Measures' })">Retour</v-btn>
      </v-card-title>
      <v-form v-model="valid" ref="form">
        <v-container>
          <v-row>
            <v-col
            >
              <v-text-field
                  v-model="currentMeasure.name"
                  :label="$t('Measures.MeasureName')"
                  required
                  outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-card outlined>
            <v-card-title v-t="'General.ListTranslations'"></v-card-title>
            <v-card-text>
              <v-row class="align-center" v-for="lang in currentMeasure.labelList" :key="lang.langValue">
                <v-col v-if="languageList && languageList.find((l) => { return l.value === lang.langValue })">
                  <v-chip>  {{ languageList.find((l) => { return l.value === lang.langValue }).country }}</v-chip>
                </v-col>
                <v-col v-if="languageList && languageList.find((l) => { return l.value === lang.langValue })">
                  <v-text-field
                      v-model="lang.label"
                      required
                      outlined
                      clearable
                      :label="$t('Measures.MeasureNameIn') + languageList.find((l) => { return l.value === lang.langValue }).country"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-col>
            <v-row>
              <v-col>
                <v-file-input
                  @click:append="svgComputed ? $store.dispatch('downloadFile', currentMeasure.measurePath) : null"
                  :append-icon="svgComputed ? 'mdi-download-circle' : null"
                  ref="fileInput"
                  color="primary"
                  class="mt-5"
                  v-model="inputFileSvg"
                  @change="showPrefixSvg = false"
                  outlined
                  accept="image/svg+xml"
                  :clearable="false"
                  :label="$t('Measures.AddMeasure')"
                  :prefix="svgComputed"
                />
                <v-file-input
                  @click:append="previewComputed ? $store.dispatch('downloadFile', currentMeasure.previewPath) : null"
                  :append-icon="previewComputed ? 'mdi-download-circle' : null"
                  color="primary"
                  v-model="inputFileImage"
                  @change="showPrefixPreview = false"
                  outlined
                  accept="image/*"
                  :clearable="false"
                  :label="$t('Measures.AddPreview')"
                  :prefix="previewComputed"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-container>
      </v-form>
      <div class='float-right pa-3' style="display: flex; align-items: center">
        <v-switch
            style="margin-right: 30px"
            v-model="currentMeasure.published"
            :label="$t('General.Publish')"
        ></v-switch>
        <v-btn :disabled="!valid" color='primary' @click='addOrModifyMeasure'>{{ $route.params.id.indexOf('new') === -1
            ? $t('General.Edit') : $t('General.Add') }}
        </v-btn>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'MeasuresForm',
  data () {
    return {
      valid: false,
      currentMeasure: {
        id: '',
        name: '',
        labelList: [],
        published: false,
        measurePath: null,
        previewPath: ''
      },
      measures: [],
      languageList: [],
      langLoaded: false,
      inputFileSvg: {},
      showPrefixSvg: true,
      uploading: false,
      inputFileImage: {},
      showPrefixPreview: true,
      uploadingImage: false
    }
  },
  computed: {
    svgComputed () {
      return this.showPrefixSvg ? this.getFileName(this.currentMeasure.measurePath) : ''
    },
    previewComputed () {
      return this.showPrefixPreview ? this.getFileName(this.currentMeasure.previewPath) : ''
    }
  },
  created () {
    if (this.$route.params.id.indexOf('new') === -1) {
      this.getCurrentMeasure()
    }
    this.$store.dispatch('getAllSites').then(() => {
      this.sites = this.$store.getters['GET_SITES']()
      this.languageList = []
      this.sites.forEach((site) => {
        site.languageList.forEach((langToAdd) => {
          if (!this.languageList.find((lang) => {
            return lang.value === langToAdd.value
          })) {
            this.languageList.push(langToAdd)
          }
        })
      })
      this.languageList.forEach((lang) => {
        if (!this.currentMeasure.labelList.find((langLabel) => {
          return langLabel.langValue === lang.value
        })) {
          this.currentMeasure.labelList.push({ langValue: lang.value, label: '' })
        }
      })
      this.langLoaded = true
    })
  },
  methods: {
    getFileName (name) {
      if (!name) {
        return ''
      }
      name = name.split('_')
      name.shift()
      return name.join('')
    },
    addSvg () {
      if (this.inputFileSvg.name) {
        this.uploading = true
        var reader = new FileReader()

        reader.onload = () => {
          var data = new FormData()
          var blob = new Blob([reader.result])
          data.append('fileName', this.inputFileSvg.name.normalize('NFD').replace(/[\u0300-\u036f]/g, ''))
          data.append('id', this.currentMeasure.id)
          data.append('file', blob)
          this.$store.dispatch('uploadMeasureSvg', data)
        }
        // read the file as text using the reader
        reader.readAsArrayBuffer(this.inputFileSvg)
      }
    },
    getCurrentMeasure () {
      this.$store.dispatch('getMeasure', this.$route.params.id).then(() => {
        this.currentMeasure = this.$store.getters['GET_CURRENT_MEASURE']()
        if (this.currentMeasure === null) {
          this.$emit('notify', {
            color: 'red',
            text: this.$t('Measures.MeasureNotFound')
          })
          this.$router.push({ name: 'Measures' })
        }
      })
    },
    addOrModifyMeasure () {
      this.$refs.form.validate()
      if (this.valid) {
        if (this.currentMeasure.id) {
          this.modifyMeasure()
        } else {
          this.addMeasure()
        }
      }
    },
    addMeasure () {
      this.$store.dispatch('addMeasure', this.currentMeasure).then(() => {
        this.currentMeasure = this.$store.getters['GET_CURRENT_MEASURE']()
        this.addSvg()
        this.uploadImage()
        setTimeout(() => {
          this.$router.push({ name: 'Measures' })
        }, 1000)
      }).catch((err) => {
        this.$emit('notify', {
          color: 'red',
          text: this.$t('Messages.' + err.response.data)
        })
      })
    },
    modifyMeasure () {
      this.$store.dispatch('editMeasure', this.currentMeasure).then(() => {
        this.addSvg()
        this.uploadImage()
        setTimeout(() => {
          this.$router.push({ name: 'Measures' })
        }, 1000)
      }).catch((err) => {
        this.$emit('notify', {
          color: 'red',
          text: this.$t('Messages.' + err.response.data)
        })
      })
    },
    uploadImage () {
      if (this.inputFileImage.name) {
        this.uploadingImage = true
        const reader = new FileReader()

        reader.onload = () => {
          const data = new FormData()
          const blob = new Blob([reader.result])
          data.append('fileName', this.inputFileImage.name)
          data.append('id', this.currentMeasure.id)
          data.append('file', blob)

          this.$store.dispatch('uploadMeasurePreview', data).then(() => {
            this.uploadingImage = false
            this.currentMeasure.previewPath = this.inputFileImage.name
          }).catch((err) => {
            this.$emit('notify', {
              color: 'red',
              text: this.$t('Messages.' + err.response.data)
            })
            this.uploadingImage = false
          })
        }

        reader.readAsArrayBuffer(this.inputFileImage)
      }
    }
  }
}
</script>

<style scoped>

</style>
